strong.page-title {
  color: black;
  font-size: 14px;
  font-weight: 600;
  display: block;
}
.page-title-small {
  color: #858585;
  display: flex;
  margin-top: 6px;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 5px 0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 5px 0.75em 0 0.75em !important;
}
.page-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d5d4d9;
  position: relative;
  padding-bottom: 56px;
  min-height: 100%;
}

th.mat-header-cell {
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}

tr.mat-row:nth-child(odd) {
  //background-color: #fafafa;
}

td.mat-cell {
  font-size: 13px;
  color: #797979 !important;
}

//th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
//  padding: 0;
//  border-bottom-width: unset !important;
//  border-bottom-style: unset !important;
//}
th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 24px;
  border-left: 3px solid #00bcd400;
}

tr:hover td.mat-cell:first-of-type {
  padding-left: 24px;
  /*border-left-color: #00bcd4 !important;*/
  border-left-color: #ff007a !important;
}

tr:hover {
  background-color: #fafafa;
}
tr td.mat-cell:first-of-type {
  transition: 300ms all;
}
tr.mat-row,
tr.mat-footer-row {
  height: 50px !important;
  cursor: pointer;
  transition: 300ms all;
}
mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: #f0f0f0 !important;
}
mat-paginator button {
  align-items: center;
  background-color: #eeeeee !important;
  margin: 7px !important;
  display: flex !important;
  justify-content: center;
  color: #686868;
  border-radius: 5px !important;
  line-height: 0px !important;
  padding: 9px !important;
}
mat-paginator button svg {
  height: 22px;
  width: 22px !important;
}

.query-builder {
  width: auto !important;

  .form-input-item {
    background-color: #bbbacd !important;
  }

  li.q-row.q-connector.q-transition.q-rule:before {
    border-right: 9px solid #bbbacd !important;
    z-index: 0 !important;
    left: -9px !important;
  }

  li.q-row.q-connector.q-transition.q-rule:after {
    border-left: 9px solid #bbbacd !important;
    z-index: 0 !important;
    right: -9px !important;
  }

  button.filter-r-add.q-button {
    position: relative;
    margin-left: unset !important;
    background-color: #0800c5 !important;
    fill: white;
    border-left: 5px solid #f2f1f8 !important;
    height: 42px !important;
    padding: 16px !important;
  }

  select.and-or-item-select {
    background-color: #bbbacd !important;
    border-left: 2px solid #aba9cd !important;
    border-right: 2px solid #aba9cd !important;
    position: relative;
    z-index: 1;
    padding: 10px;
  }

  select.and-or-select {
    margin: 0 14px;
    height: 42px;
    background-color: unset !important;
    font-weight: bold !important;
    border-radius: 5px !important;
    padding: 6px;
    border: 1px solid #cbcbcb !important;
  }

  .invalid:hover {
    background-color: #ff00001c !important;
    color: #ff0000 !important;
  }

  .invalid {
    background-color: #ff000014 !important;
    color: #ff0000 !important;
  }

  button.add-filter.q-button {
    border: 1px solid #0027ff !important;
    padding: 3px 19px !important;
    border-radius: 5px;
    height: 42px !important;
  }

  .q-tree {
    margin: 0 !important;
  }

  input.q-input-control[type='checkbox'] {
    height: 20px;
    width: 20px !important;
    margin: 9.8px 4px !important;
  }

  textarea.text-input.text-area {
    height: 40px !important;
  }

  .q-entity-control,
  .q-field-control,
  .q-input-control:not([type='checkbox']),
  .q-operator-control {
    min-height: 40px !important;
    padding: 7px 10px !important;
  }

  li.q-row.q-connector.q-transition.q-rule:after,
  li.q-row.q-connector.q-transition.q-rule:before {
    content: '';
    border-top: 21px solid transparent !important;
    border-bottom: 22px solid transparent !important;
  }

  button.removeButton {
    top: 5px;
  }

  .mat-form-field-label-wrapper {
    display: none !important;
  }

  .mat-select-arrow {
    border-left: unset !important;
    border-right: unset !important;
    border-top: unset !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 !important;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: 5px;
  }

  .and-or-select {
    background-color: #a3a2ba;
    width: 66px;
    text-align: center;
    border-right: 1px solid #a4a4a4;
    border-left: 1px solid #a4a4a4;
    position: relative;
    z-index: 2;
    font-weight: 500;
  }

  .query-builder-textarea .mat-form-field-infix {
    padding: 5px !important;
    background-color: white;
  }

  .query-builder-textarea textarea {
    height: 32px !important;
  }
}

.query-builder .mat-form-field-underline {
  display: none;
}

.query-builder .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.query-builder .mat-form-field-infix {
  border: 0 !important;
  padding: 13px !important;
  width: 100px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0800c5 !important;
}
.bg-w {
  background-color: white;
}

@media (max-width: 1024px) {
  tr.mat-row,
  tr.mat-footer-row {
    height: 45px !important;
  }
}


.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 9px 0px 9px 0px !important;
}
