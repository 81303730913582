.h-100 {
  height: 100% !important;
}
.h-75 {
  height: 75% !important;
}
.h-50 {
  height: 50% !important;
}
.h-25 {
  height: 25% !important;
}
.w-100 {
  width: 100% !important;
}
.w-75 {
  width: 75% !important;
}
.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}
.h-auto {
  height: auto !important;
}
.w-auto {
  width: auto !important;
}

.mw-auto {
  min-width: auto !important;
}
