.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.cursor-pointer {
  cursor: pointer;
}
.outline-none {
  outline: none;
}

h1.h-1 {
  font-size: 24px;
  font-weight: 500;
}
