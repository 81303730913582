*,
*::before,
*::after {
  box-sizing: border-box;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: #fff;
  -webkit-tap-highlight-color: transparent !important;
}

//* {
//  scrollbar-width: thin;
//  scrollbar-color: #f3e1ff #ddb4f9;
//  color-scheme: light only;
//}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: inherit !important;
}

.mat-table {
  font-family: inherit !important;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font-family: 'Poppins', sans-serif !important;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  transition: width 500ms;

}


*::-webkit-scrollbar-track {
  background: #e6e6e6;
}

*::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 3px;
}

.right-sid-model-container {
  position: absolute !important;
  right: 0;
  top: 0;
  width: 800px;
  height: 100%;
  overflow: auto;
  box-shadow: -4px 0px 16px #00000059;
  background-color: #f2f2f2;
}

.right-sid-model-big-container {
  position: absolute !important;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: auto;
  box-shadow: -4px 0px 16px #00000059;
}

.right-sid-model-container mat-dialog-container,
.right-sid-model-big-container mat-dialog-container {
  padding-top: 0 !important;
}

.right-sid-model-container .fix-model-header,
.right-sid-model-big-container .fix-model-header {
  position: sticky;
  top: 0;
  padding: 15px;
  padding-left: 27px !important;
  margin: -24px;
  left: 0;
  margin-top: 0;
  margin-bottom: 18px;
  display: flex;
  z-index: 1000;
  background-color: #e1e1e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.model-close-btn {
  position: absolute !important;
  left: 11px;
  top: 13px;
}

table.mat-table td,
table.mat-table th {
  padding: 0 15px;
  white-space: nowrap;
}

.right-sid-model-big-container mat-dialog-container,
.right-sid-model-container mat-dialog-container {
  background-color: #ffffff;
}

.scroll {
  overflow: auto;
}

.center-model-padding-remove {
  mat-dialog-container {
    padding: 0px !important;
    border-radius: 10px !important;
  }
}

.center-model-feedback-bg-color {
  .mat-dialog-container {
    background-color: #DE3A97 !important;
  }
}

.center-model-introduction-bg-color {
  .mat-dialog-container {
    background-color: #742B96 !important;
  }
}


.center-model {
  width: 80%;
  max-height: 80vh !important;

  .center-fix-model-header {
    position: sticky;
    top: 0;
    margin: 0 -24px;
    padding: 15px 60px 15px 15px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
    z-index: 9;

    button.center-model-close {
      position: absolute;
      right: 10px;
      top: 13px;
    }

    h2 {
      font-size: 16px;
      margin: 0;
    }
  }

  mat-dialog-container {
    padding-top: 0;
    z-index: 9999;
  }
}

.center-model-55 {
  width: 55%;
  max-height: 80vh !important;
}

.touch-device {
  width: 30%;

  .mat-dialog-container {
    background: #f9f9f9;
  }
}

.release-center-model {
  width: 40%;

  .center-fix-model-header {
    position: sticky;
    top: 0;
    padding: 15px 60px 15px 15px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
    z-index: 9;

    button.center-model-close {
      position: absolute;
      right: 10px;
      top: 13px;
    }

    h2 {
      font-size: 16px;
    }
  }

  mat-dialog-container {
    padding: 0px !important;
    z-index: 9999;
  }
}

.center-big-model {
  width: 50%;
  max-height: 80vh !important;

  .center-fix-model-header {
    position: sticky;
    top: 0;
    margin: 0 -24px;
    padding: 15px 60px 15px 15px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
    z-index: 9;

    button.center-model-close {
      position: absolute;
      right: 10px;
      top: 13px;
    }

    h2 {
      font-size: 16px;
      margin: 0;
    }
  }

  mat-dialog-container {
    padding-top: 0;
    z-index: 9999;
  }
}

.center-90vh-big-model {
  width: 90%;
  min-width: 90%;
  height: 90vh;

  .center-fix-model-header {
    position: sticky;
    top: 0;
    margin: 0 -24px;
    padding: 15px 60px 15px 15px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
    z-index: 9;

    button.center-model-close {
      position: absolute;
      right: 10px;
      top: 13px;
    }

    h2 {
      font-size: 16px;
      margin: 0;
    }
  }

  mat-dialog-container {
    padding-top: 0;
    z-index: 9999;
  }
}

.center-model-auto-w {
  .center-fix-model-header {
    position: sticky;
    top: 0;
    margin: 0 -24px;
    padding: 15px 60px 15px 15px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
    z-index: 9;

    button.center-model-close {
      position: absolute;
      right: 10px;
      top: 13px;
    }

    h2 {
      font-size: 16px;
      margin: 0;
    }
  }

  mat-dialog-container {
    padding-top: 0;
  }
}

.form-filed-padding-bottom-remove .mat-form-field-wrapper {
  padding-bottom: 0em !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 12px 0 12px 0 !important;
}

.mat-form-field-infix {
  border-top: 9px solid transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}

.page-card mat-paginator.mat-paginator {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #fafafa;
}

.form-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.form-card-overflow-full {
  background-color: white;
  border-radius: 8px;
}

.empty-data-img {
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 177px;
    opacity: 0.5;
    margin-bottom: 15px;
  }

  small {
    text-align: center;
    display: block;
    margin-bottom: 15px;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.mat-paginator-outer-container {
  white-space: nowrap;
}

.profile-info-container-header label {
  color: #40497b !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.big-center-model {
  width: 60%;

  mat-dialog-container {
    padding-bottom: 0px !important;
  }
}

@media (min-width: 1200px) {
  .center-model {
    max-height: 80vh !important;
    position: absolute !important;
  }
}


@media (min-width: 768px) and (max-width: 1200px) {
  .right-sid-model-big-container {
    width: 80%;
  }
  .center-model {
    width: 70%;
    //height: 90%;
  }
  .center-big-model {
    width: 80%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .right-sid-model-big-container {
    width: 100%;
    max-width: 100vw !important;
  }
  .right-sid-model-container {
    width: 100%;
    max-width: 100vw !important;
  }
  .fix-model-header h2 {
    font-size: 16px !important;
  }
  .center-model {
    width: 80%;
    height: 90%;
  }
  .center-big-model {
    width: 100%;
  }
}

/*phone control style*/
mat-phone-control {
  ul.country-selection li img {
    margin-right: 25px !important;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .custom-options {
    width: 245px !important;
    max-height: 200px !important;
    min-width: 233px !important;
    max-width: 196px !important;
    border-radius: 7px !important;
    padding: 5px 0 !important;
  }

  .custom-option {
    display: block !important;
  }

  ul.country-selection li span {
    font-size: 14px !important;
  }

  ul.country-selection li small {
    font-size: 12px !important;
    color: #7b7b7b !important;
  }

  .custom-option {
    padding: 12px 12px !important;
  }

  .countery-code {
    max-width: 50px !important;
    padding: 0 7px !important;
    font-size: 14px !important;
    min-width: 50px !important;
  }

  input {
    font-size: 14px !important;
    padding: 0 10px !important;
  }
}

.auto-w-input {
  .mat-form-field-infix {
    width: auto !important;
  }
}

@media (max-width: 1024px) {
  .empty-data-img {
    height: 180px;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;

  h3 {
    font-weight: 500 !important;
    color: #fdfdfd;
    margin: 0 !important;
  }
}

.page-header {
  padding: 15px 15px 55px;
  background-color: #742b96;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

tr.mat-header-row {
  background-color: #e6e6e6 !important;
  height: 56px !important;
}

.page-header-none-color {
  display: flex;
  justify-content: space-between;

  h3 {
    font-weight: 500 !important;
    color: #000000;
    margin: 0 !important;
  }
}

table.mat-table mat-icon {
  height: 24px;
  width: 18px;
  font-size: 18px;
}

table.mat-table button {
  font-size: 12px;
}

@media (max-width: 767px) {
  .center-model-introduction-bg-color {
    height: 100% !important;
  }
}

@media (max-width: 576px) {
  //.scroll {
  //
  //}
  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  .release-center-model {
    width: 80%;
  }
}

@media (max-width: 768px) {
  //.d-h-100pr {
  //  height: calc(100% - 60px);
  //}
  .release-center-model {
    width: 80% !important;
  }
}

.ngx-mat-color-collection.ngx-mat-color-collection .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ngx-mat-color-canvas .row:first-of-type {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ngx-mat-color-canvas .row:nth-of-type(2) {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ngx-mat-color-collection .btn-color {
  margin-top: 10px !important;
}

.ngx-mat-colorpicker-dialog .mat-dialog-container {
  padding: 0 !important;
}

.ngx-mat-colorpicker-dialog {
  max-width: 100vw !important;
}

.success-toast {
  background-color: green !important;
  color: white !important;
}

.warn-toast {
  background-color: yellow !important;
  color: black !important;
  cursor: pointer;
}

.error-toast {
  background-color: darkred !important;
  color: white !important;
  cursor: pointer;
}

.card-height-calc {
  height: calc(100% - 76px);
}

.card-body {
  border-radius: 7px;
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 100%;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
}

.paginator-absolute {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.scroll-table {
  position: absolute;

  thead {
    position: sticky;
    top: 0;
    z-index: 9;
  }
}

.custom-popover {
  z-index: 99 !important;
}

.not-graded-link {
  text-decoration: underline;
  color: blue;
}

* {
  touch-action: manipulation;
}


//

tree-root {
  .folder-icon {
    font-size: 16px !important;
    height: 16px;
    color: #ff9800;
  }

  .delete-icon {
    color: red !important;
    font-size: 16px !important;
  }

  .save-iocn {
    font-size: 16px !important;
  }

  .edit-icon {
    font-size: 16px !important;
  }

  .add-icon {
    color: green !important;
    font-size: 16px !important;
  }

  tree-node-content {
    display: flex !important;
    align-items: center;

    .title-folder {
      display: flex !important;
      align-items: center !important;
      font-size: 13px;
      color: #4e4d4d;
      white-space: pre;
    }
  }

  .node-wrapper {
    display: flex;
    align-items: center;
  }

  .mat-icon-button {
    width: 25px !important;
    height: 25px !important;
    line-height: 25px !important;
  }

  .angular-tree-component {
    width: unset !important;
  }

  .node-content-wrapper-active, .node-content-wrapper.node-content-wrapper-active:hover, .node-content-wrapper-active.node-content-wrapper-focused {
    background: #e6e6e6 !important
  }

  .node-content-wrapper {
    padding: 0px 10px !important;
    border-radius: 6px !important;
    box-shadow: unset !important;

  }
}

.add-folder-form {
  padding: 15px 5px 9px;
}

@media screen and (max-width: 1120px) {
  .card-md-xs-pb-105 {
    padding-bottom: 105px !important;
  }
}

@media screen and (min-width: 768px) {
  .pl-xl-0 {
    padding-left: 0 !important;
  }

  .assignment-folder-inner-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  .assignment-folder-container {
    flex: 1;
    position: relative;
  }
}

.assignment-folder-inner-container {
  padding: 15px;
  margin-right: 15px;
}

.sm-chip {
  min-height: 24px !important;
  font-size: 12px !important;
  margin: 4px 3px !important;
}

.select-chip {
  min-height: 30px !important;
  font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.assignment-chip {
  position: relative;
  background-color: #e7e7e7;
  display: inline-block;
  padding: 8px 36px 8px 13px;
  border-radius: 60px;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  button {
    position: absolute;
    border: none;
    background-color: unset;
    font-size: 18px;
    height: 20px;
    top: 7px;
    right: 0px;

    mat-icon {
      font-size: 20px;
      height: 20px;
      color: #919191;
    }
  }
}

//.edit-icon {
//  right: 5px !important;
//}

.introduction-edit-icon {
  right: 28px !important;
  cursor: pointer;
}

.introduction-delete-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;

  icon {
    color: #c30000;
  }
}

span.rapid-chips {
  padding: 2px 10px 2px 22px;
  align-items: center;
  position: relative;
  font-size: 12px;
  background-color: #4caf500d;
  border-radius: 29px;
  color: #4caf50;
  border: 1px solid #4caf50;
  margin-left: 15px;

  mat-icon {
    height: 13px;
    display: inline-block;
    position: absolute;
    left: 2px;
    font-size: 14px;
    top: 2px;
  }
}

.long-text-ellipsis {
  min-width: 100px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  max-width: 250px;
}

.menu-btn {
  border: 1px solid gainsboro !important;
  width: 35px !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.gride-btn {
  border: 1px solid gainsboro !important;
  border-radius: 100px !important;
  line-height: 1.7 !important;
  padding: 0 5px !important;
  margin: 0 3px !important;
  font-size: 11px !important;

  mat-icon {
    height: 12px !important;
    font-size: 12px !important;
    margin-right: 5px !important;
    width: 12px !important;
    border-right: 1px solid gainsboro !important;
    padding-right: 15px !important;
  }
}

.start-recording {
  app-wave-surfer-audio-recorder {
    wave {
      height: 30px !important;
    }
  }
}

.generate-hours {
  height: unset !important;
}

mat-paginator {
  .mat-paginator-container {
    justify-content: start !important;
  }
}

.request-demo-model {
  .center-fix-model-header {
    border-bottom: 1px solid #dfefff !important;
    background-color: #dfefff !important;
  }

  .mat-dialog-container {
    background: #edf6ff;
  }
}


.resume-practice-model {
  width: 40%;
  max-width: 100% !important;

  .mat-dialog-container {
    position: relative;
    overflow: hidden;
    &:before{
      position: absolute;
      content: "";
      width: 110%;
      height: 330px;
      background-color: #232323;
      left: -35px;
      top: -50%;
      border-radius: 100%;
    }
    .stacker-image {
      img {
        height: 160px;
      }
    }
    .star-image {
      text-align: center;
      position: relative;
      img {
        height: 150px;
      }
    }
  }
  .one-digit {
    font-size: 50px !important;
  }

}


@media screen and (max-width: 768px) {
  .resume-practice-model {
    .mat-dialog-container {
      .stacker-image {
        img {
          height: 120px;
        }
      }

      .star-image {
        margin-top: 8px !important;
        margin-bottom: 5px !important;

        img {
          height: 130px !important;
        }
      }
    }

    .one-digit {
      font-size: 48px !important;
      //-webkit-text-stroke: 4px #393031 !important;
    }
  }
  h3 {
    margin: 0 !important;
  }

  .attempts-model {
    position: relative !important;

    mat-dialog-container {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1440px) {
  .resume-practice-model {
    width: 55%;
  }
}

@media screen and (max-width: 1024px) {
  .resume-practice-model {
    width: 95%;
  }
  .resume-practice-model .mat-dialog-container h2 {
    font-size: 28px !important;
  }
  .resume-practice-model .mat-dialog-container h3{
    font-size: 20px !important;
  }
  .continue-btn {
    padding: 0px 15px !important;
    font-size: 14px !important;
  }
  .resume-practice-model .mat-dialog-container .star-image img {
    height: 85px !important;
  }
  .resume-practice-model .mat-dialog-container .stacker-image img {
    height: 120px !important;
  }
  .praction-grading-header span {
    font-size: 15px;
  }
  .resume-practice-model .mat-dialog-container:before {
    width: 110%;
    height: 311px;
    left: -15px;
    top: -50%;
  }
  .praction-grad-contant {
    width: 140px !important;
    height: 140px !important;
    margin-top: 18px !important;
    margin-bottom: 14px !important;
  }
}
